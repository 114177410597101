// Sometimes we have local data (e.g. localStorage) that is an invalid old format and needs to be moved to a new version.
// Typically this migration should
// 1. Happen only if the user has a minimum version of the code (either native app version or git SHA for server version)
// 2. Happen only once (i.e. we should create some persistent marker that the migration has happened)
//
// An assumption of this system is that localStorage exists. If it doesn't exist, we don't currently have a fallback
// (e.g. cookies) so we just fail silently and make sure we don't keep retrying forever.
//
// Having these migrations present does slow down the codebase, so we should remove them once we're confident a majority of
// users have received the migration. We should also be careful to apply them as selectively as possible to end users - i.e.
// only to those certain to need it.

import hasLocalStorageSupport from 'modernizr-esm/feature/storage/localstorage';
import { onLoad } from './app/utils';
import { buildVersion, isAndroid, isApp } from './native-app/app-utils';

//===================
// HELPERS
//===================

const MIGRATION_KEY = 'localDataMigration';

type MIGRATION_NAMES_TYPE =
  | 'fixInvalidBackHistoryInLocalStorage'
  | 'fixSearchViewPreferencesInLocalStorage';

const markMigrationAsApplied = (migrationName: MIGRATION_NAMES_TYPE) => {
  const currentState = currentMigrationState();
  currentState[migrationName] = true;
  localStorage.setItem(MIGRATION_KEY, JSON.stringify(currentState));
};

const currentMigrationState = () => {
  const jsonCurrentState = localStorage[MIGRATION_KEY];
  return jsonCurrentState ? JSON.parse(jsonCurrentState) : {};
};

const applyMigration = (migrationName: MIGRATION_NAMES_TYPE, migration: () => boolean) => {
  onLoad(() => {
    if (typeof Storage === 'undefined') {
      // eslint-disable-next-line no-console
      console.log('Local storage not available, skipping migration');
      return;
    }

    const currentState = currentMigrationState();
    if (!currentState[migrationName]) {
      const isMigrationSuccessful = migration();
      if (isMigrationSuccessful) {
        markMigrationAsApplied(migrationName);
        alert(
          `We've applied a once-off fix to your app's data: ${migrationName}. This should only happen once per device.`
        );
      }
    }
  });
};

// Set this to `true` to test without needing to trigger the exact version requirements.
// DANGER Don't forget to undo before committing.
const testing = false;
const runEverywhere = true;

if (!hasLocalStorageSupport) {
  console.error('This device does not have localStorage support');
} else {
  //===================
  // MIGRATIONS
  //===================
  //

  if (testing || (isApp() && isAndroid() && buildVersion() >= 60)) {
    // EXAMPLE -- do not uncomment and use as is
    // ===================
    // const fixInvalidBackHistoryInLocalStorage = () => {
    //   localStorage.setItem('previousUrls', JSON.stringify(['/', '/', '/', '/', '/']));
    //   return true;
    // };
    // const migrationName = 'fixInvalidBackHistoryInLocalStorage';
    // applyMigration(migrationName, fixInvalidBackHistoryInLocalStorage);
    // ===================
  }
}
